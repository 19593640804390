import React, { useEffect, useState } from 'react'
import cross from "../../../../images/+.svg"
import { PlayCircle } from 'react-feather'
import { FaRegFilePdf } from "react-icons/fa6"
import { Card, CardBody, Col, Label, Modal, ModalBody, Row } from 'reactstrap'
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import DropDown from '../../../../@core/components/DropDown/DropDown'
import leftArrow from "../../../../images/leftArrow.svg"
import addImage from "../../../../images/addImage.svg"
import { FaRegFolderClosed } from "react-icons/fa6";
import { UploadCloud } from 'react-feather'
import { X } from 'react-feather'
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert'
import { toast } from "react-toastify"
import axios from 'axios'
import ReactPaginate from "react-paginate";
import { ChevronLeft, ChevronRight } from "react-feather";
import "./index.css"
import CustomInputComponent from '../../../../@core/components/common/customInput'
import { InfinitySpin } from 'react-loader-spinner'

const SpinnerComponent = () => {
    return (<div className='spinner-component'>
        <InfinitySpin
            visible={true}
            width="200"
            color="#0258a1"
            ariaLabel="infinity-spin-loading"
        />
    </div>)
}

const UploadFile = ({ handleUpload, topicIds, isOpen, setIsOpen, data, editId }) => {
    const [flag, setFlag] = useState(true)
    const baseUrl = process.env.REACT_APP_API_URL
    const authentication_key = localStorage.getItem('authentication_key');
    const headers = {
        'Authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
    };
    const [currentPage, setCurrentPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalPage, setTotalPage] = useState(1)
    const [tableData, setTableData] = useState([])
    const [selectedArray, setSelectedArray] = useState([])
    const [load, setLoad] = useState()
    const handlePagination = (page) => {
        setCurrentPage(page.selected)
    }
    const handleDeleteImg = (id) => {
        axios.postForm(`${baseUrl}/partner/curriculum-file-destroy`, {
            id,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                setFlag(!flag)
                toast.success(data.message)
            } else {
                toast.error(data.message)
            }
        }
        ).catch(err => {
            console.log(err)
        })

    }

    const handleDeleteData = (row) => {
        setIsOpen(!isOpen)
        confirmAlert({
            title: "Confirm delete",
            message: 'Are you sure to delete?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setIsOpen(true)
                        handleDeleteImg(row.file_id)
                    },
                },
                {
                    label: 'No',
                    onClick: () => {
                        setIsOpen(true)
                        // Handle the action when 'No' is clicked
                        // console.log('You clicked No!');
                    },
                },
            ],
            // Custom styles for the confirm pop-up
            overlayClassName: 'custom-overlay-class',
            className: 'custom-modal-class',
        });
        // confirmAlert({
        //     title: "Confirm delete",
        //     message: "Sure delete",
        //     buttons: [
        //         {
        //             label: "Yes",
        //             className: "confirmYesBtn",
        //             // onClick: () => handleDeleteAction(row.id)
        //         },
        //         {
        //             label: "No",
        //             className: "confirmNoBtn"
        //             // onClick: () => alert('Click No')
        //         }
        //     ]
        // })
    }
    //handleSelectDoc
    const handleSelectDoc = (row) => {
        row["mark_demo"] = "no"
        const forCheck = selectedArray.map(io => io.file_id)
        if (row && forCheck.includes(row.file_id)) {
            setSelectedArray([...selectedArray.filter(io => io.file_id !== row.file_id)])
        } else {
            setSelectedArray([...selectedArray, row])
        }
    }
    console.log(selectedArray)
    const handleGetFilesData = () => {
        setLoad(true)
        const payload = {
            page: currentPage,
            perPage: rowsPerPage,
            auth_bearer_token: authentication_key
        }
        axios.get(`${baseUrl}/partner/curriculum-file-table-data`, {
            headers,
            params: payload
        }).then(res => {
            const response = res.data
            if (response.status === "success") {
                const data = response.data
                setTableData(data)
                setTotalPage(response.total_pages)
                setLoad(false)
            } else {
                console.log(response.message)
            }
            // dispatch(handleLogin(res.data.data.user_data))
            // navigate('/modules/home')
        }
        ).catch(err => {
            console.log(err)
        })
    }
    console.log(tableData)
    function isFileTypeAllowed(file) {
        // Get the file extension
        const extension = file.name.split('.').pop().toLowerCase();

        // Check if the file type is allowed
        if (extension !== 'xls' && extension !== 'xlsx' && extension !== "mp4" && !file.type.startsWith('video/')) {
            return true;
        }
        return false;
    }

    const handleDrop = (event) => {
        event.preventDefault()
        const file = event.dataTransfer.files[0]
        handleUploadImg(file)
    }

    const handleUploadImg = (file) => {
        if (isFileTypeAllowed(file)) {
            axios.postForm(`${baseUrl}/partner/curriculum-file-store`, {
                userfile: [file],
                name: file.name,
                auth_bearer_token: authentication_key
            }, {
                headers: {
                    'Content-Type': 'application/json', // Set the content type based on your API requirements
                    'authentication_key': authentication_key, // Include any authorization token if needed
                    'Authorization': 'Bearer ' + authentication_key,
                    // Add other headers as needed
                }
            }).then(res => {
                const data = res.data
                if (data.status === "success") {
                    toast.success("Successfully uploaded")
                    setFlag(!flag)
                } else {
                    toast.error(data.message)
                }
            }
            ).catch(err => {
                console.log(err)
            })
        } else {
            toast.error("Unsupported file type")
        }
    }

    useEffect(() => {
        handleGetFilesData()
    }, [flag, currentPage])

    useEffect(() => {
        if (topicIds && topicIds.length > 0 && data) {
            if (topicIds.length === "2") {
                setSelectedArray([...data[topicIds[0]].topics[topicIds[1]].images])
            } else if (topicIds.length === "3") {
                setSelectedArray([...data[topicIds[0]].topics[topicIds[1]].sub_topics[topicIds[2]].images])
            }

        }
    }, [topicIds])
    const poster = {
        pdf: "https://logowik.com/content/uploads/images/adobe-pdf3324.jpg",
        vimeo: "https://www.svgrepo.com/show/160699/vimeo-logo-in-a-square.svg",
        default: "https://png.pngtree.com/png-vector/20190321/ourmid/pngtree-vector-folder-icon-png-image_855010.jpg"
    }
    return (
        <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="modal-xl" centered={true}>
            <div className='d-flex justify-content-between align-items-center p-4 flex-wrap'>
                <div className='d-flex justify-content-between align-items-center gap' >
                    <div className='d-flex justify-content-center align-items-center left-arrow-image' onClick={() => setIsOpen(!isOpen)}>
                        <img src={leftArrow} />
                    </div>
                    <div className='page-heading'>Upload File</div>
                </div>
                <div onClick={e => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }}>
                    <X size={30} color='#C4C4C4' strokeWidth={3.5} />
                </div>
            </div>
            <ModalBody>
                <input
                    className='visually-hidden'
                    type="file"
                    id='imgFile'
                    accept="*"
                    // multiple={true}
                    onChange={(e) => handleUploadImg(e.target.files[0])}
                // onChange={handleFileSelect}
                />
                <div className='img-drop-container w-100'>
                    <div className='dropzone-container  w-100'
                        onDrop={handleDrop}
                        onDragOver={(event) => event.preventDefault()}
                    >
                        <Label className='d-block d-flex flex-column justify-content-between align-items-center' htmlFor='imgFile'>
                            <div >
                                <img className='w-100' src={addImage} alt='#' />
                            </div>
                            <div className='drag-label'>Drag & Drop to upload</div>
                            <div className='drag-description'>Supported formats include JPEG, JPG, and PNG, up to a maximum size of 5MB</div>
                        </Label>
                    </div>
                </div>
                {load ? (<>
                    <SpinnerComponent />
                </>) : (<>
                    <div className='d-flex flex-wrap gap-4 mt-3'>
                        {tableData.map(io => (<>
                            <div
                                style={{
                                    width: '250px',
                                    height: '198px',
                                    borderRadius: "8px",
                                    boxShadow: "0px 0px 5px 0px #001B4A33",
                                    // padding:'1rem'
                                    border: "0.5px solid #C4C4C4",
                                    overflow: 'hidden'

                                }}
                            >
                                <div className='h-75' style={{ position: 'relative', backgroundImage: `url(${io.file_type === "pdf" ? poster.pdf : io.file_type === "vimeo" ? poster.vimeo : io.file_path})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center" }}>
                                    {/* <img
                                        alt="Sample"
                                        src={io.file_type === "pdf" ? poster.pdf : io.file_type === "vimeo" ? poster.vimeo : io.file_path}
                                        className='w-100 h-100'
                                    /> */}
                                    <div onClick={() => handleDeleteData(io)} style={{ position: "absolute", top: '15px', right: '15px', height: "20px", width: '20px', borderRadius: "100%", backgroundColor: "#FFFFFF", cursor: 'pointer' }} className='d-flex justify-content-center align-items-center'>
                                        <X color='#838383' size={13} strokeWidth="4px" />
                                    </div>
                                </div>

                                {/* <CardBody > */}
                                {/* <div className='data-type-icon'>
                                    <PlayCircle />
                                    <FaRegFilePdf />
                                </div> */}
                                <div className='d-flex align-items-center h-25 px-2'>
                                    <CustomInputComponent
                                        onClick={() => handleSelectDoc(io)}
                                        type="checkbox"
                                        name="select-row-undefined"
                                        id={`custom-checkbox-${io.file_id}}_`}
                                        checked={selectedArray.map(elm => elm.file_id).includes(io.file_id)}
                                    />
                                    <div className='px-2 doc-name'>{io.name}</div>
                                </div>
                                {/* </CardBody> */}
                            </div>
                        </>))}
                    </div>
                    <div className="d-flex justify-content-end w-90 my-2">
                        <ReactPaginate
                            pageCount={totalPage}
                            nextLabel={<ChevronRight size={15} color="#0258a1" />}
                            breakLabel="..."
                            previousLabel={<ChevronLeft size={15} color="#0258a1" />}
                            activeClassName="active"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            forcePage={currentPage !== 0 ? currentPage : 0}
                            onPageChange={(page) => handlePagination(page)}
                            pageClassName={"page-item"}
                            nextLinkClassName={""}
                            nextClassName={"page-item next"}
                            previousClassName={"page-item prev"}
                            previousLinkClassName={""}
                            pageLinkClassName={"page-link"}
                            containerClassName={
                                "pagination react-paginate align-items-center py-4"
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-end w-90">
                        <ButtonComponent
                            buttonType={"primary"}
                            title="Upload"
                            onClick={() => handleUpload(selectedArray)}
                        />
                    </div>
                </>)}

            </ModalBody>

        </Modal>
    )
}

export default UploadFile