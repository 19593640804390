import React, { useEffect, useState } from 'react'
import leftArrow from "../../../../images/leftArrow.svg"
import newcurriculamImage from "../../../../images/newcurriculam.svg"
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import { useLocation, useNavigate, useBeforeUnload } from 'react-router-dom'
import loadable from "react-loadable";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, FormGroup, Row } from 'react-bootstrap'
import CustomInput from '../../../../@core/components/CustomInput/CustomInput'
import DropDown from '../../../../@core/components/DropDown/DropDown'
import CardWithImage from '../../../../@core/components/common/CardWithImage'
import cross from '../../../../images/+.svg'
import AddLinkModal from '../../../../@core/components/common/AddLinkModal'
import SchoolCard from '../../../../@core/components/common/SchoolCard'
import Toggle from 'react-toggle'
import leftTiltedArrow from "../../../../images/leftTiltedArrow.svg"
import rightTiltedArrow from "../../../../images/rightTiltedArrow.svg"
import file from "../../../../images/addcuriculamfile.svg"
import ModalAssign from './ModaAssign'
import { element, func } from 'prop-types'
import CustomInputComponent from '../../../../@core/components/common/customInput'
import crossArrow from "../../../../images/crossarrow.svg"
import downArrow from '../../../../images/downarrow.svg'
import completed from "../../../../images/completed.svg"
import pdf from '../../../../images/pdf.svg'
import UploadFile from '../UploadFile'
import { XCircle } from "react-feather"
import axios from 'axios'
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Menu, Trash2, Plus, Link2 } from 'react-feather'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import CustomInputWithIncDec from '../../../../@core/components/CustomInputWithIncDec'
import SelectedButton from '../../../../@core/components/SelectedButton'
import { RxFileText } from "react-icons/rx";
import { InfinitySpin } from "react-loader-spinner";
import "./NewCurriculam.css"
import MultiSelect from '../../../../@core/components/Multiselect/MultiSelect'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import TextEditor from '../../../../@core/components/TextEditor/TextEditor'
import { Label } from 'reactstrap'
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert'
import DotsSixVertical from "./DotsSixVertical.svg"
const SpinnerComponent = () => {
    return (
        <div className="spinner-component">
            <InfinitySpin
                visible={true}
                width="200"
                color="#0258a1"
                ariaLabel="infinity-spin-loading"
            />
        </div>
    );
};
const UploadMediaLink = loadable({
    loader: () => import(/* webpackChunkName: 'Curriculum' */ "./UploadMediaLink"),
    loading: () => <></>,
});


const NewCurriculam = () => {
    const baseUrl = process.env.REACT_APP_API_URL
    const userData = useSelector(state => state.auth.userData)
    const authentication_key = localStorage.getItem('authentication_key');
    const navigation = useNavigate()
    const location = useLocation()
    const [flag, setFlag] = useState(false)
    const [isInputFocused, setIsInputFocused] = useState("");
    const [newCurriculamState, setNewCurriculamState] = useState('basic-details')
    const [country, setCountry] = useState(null)
    const [editId, setEditId] = useState("")
    const [show, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [selectCate, setSelectCate] = useState("");
    const [categoryDropdown, setCategoryDropdown] = useState([])
    const [classDropdown, setClassDropdown] = useState([])
    const [classId, setClassId] = useState("")
    const [description, setDescription] = useState("")
    const [content_status, setContent_status] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [addFiles, setAddFiles] = useState(false)
    const [subjectArray, setSubjectArray] = useState([])
    const [isFileModal, setIsFileModal] = useState(false)
    const [showTopicImg, setShowTopicImg] = useState([])
    const [isActiveNext, setIsActiveNext] = useState(false)
    const [draftStatus, setDraftStatus] = useState("1")
    const [load, setLoad] = useState(false)
    const [mediaLinkModalOpen, setMediaLinkModalOpen] = useState(false)
    const [visibleTopics, setVisibleTopics] = useState({});
    const [activeSubjects, setActiveSubjects] = useState([]);
    const [activeTopics, setActiveTopics] = useState({});
    const [selectedType, setSelectedType] = useState({});
    const [activeActions, setActiveActions] = useState({});
    const [activeSubTopic, setActiveSubTopic] = useState(null); // To track the visible subtopic
    // const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState([]);
    const [notesDemoMark, setNotesDemoMark] = useState(false)
    const [options, setOptions] = useState([]);
    console.log(setShowTopicImg)

    const [newDemoData, setNewDemoData] = useState([{
        name: "",
        topics: [
            {
                name: "",
                topic_days: 0,
                topic_grace: 0,
                file_data: [],
                sub_topics: [
                    {
                        name: "",
                        topic_days: 0,
                        sub_topic_type: "'Sub Topic'",
                        topic_grace: 0,
                        file_data: [],
                        skill_id: "",
                        diary_note: "",
                        gallery: "",
                        teacher_note_show: false,
                        description_show: false
                    }
                ]
            }
        ]
    }])
    console.log(newDemoData)
    const [isBlocking, setIsBlocking] = useState(true);
    const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
    const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0 });

    // Block navigation on reload/refresh
    useBeforeUnload((event) => {
        if (isBlocking) {
            event.preventDefault();
            event.returnValue = ""; // Standard way to prompt user
        }
    });

    const handleBeforeUnload = (event) => {
        if (isBlocking) {
            event.preventDefault();
            event.returnValue = ""; // This triggers the default browser confirmation dialog
        }
    };

    // Attach navigation blocking for back/forward navigation
    useEffect(() => {
        const handleBlockNavigation = (event) => {
            const confirmationMessage = "Do you really want to leave this page?";
            if (isBlocking) {
                const userConfirmed = window.confirm(confirmationMessage);
                if (userConfirmed) {
                    navigation(-1); // Navigate back if the user confirms
                } else {
                    event.preventDefault(); // Prevent the navigation if the user cancels
                }
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('popstate', handleBlockNavigation);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('popstate', handleBlockNavigation);
        };
    }, [isBlocking, navigation]);

    // Detect swipe gestures to confirm navigation
    useEffect(() => {
        const handleTouchStart = (e) => {
            setTouchStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
        };

        const handleTouchEnd = (e) => {
            setTouchEnd({ x: e.changedTouches[0].clientX, y: e.changedTouches[0].clientY });
        };

        const detectSwipeGesture = () => {
            const xDiff = touchStart.x - touchEnd.x;
            const yDiff = touchStart.y - touchEnd.y;

            // Detect a significant horizontal swipe (ignore vertical swipes)
            if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > 50) {
                if (xDiff > 0) {
                    // Swipe left
                    const confirmationMessage = "Do you really want to navigate left?";
                    if (window.confirm(confirmationMessage)) {
                        navigation(-1); // Navigate back on swipe left
                    }
                } else {
                    // Swipe right (optional)
                    const confirmationMessage = "Do you really want to navigate right?";
                    if (window.confirm(confirmationMessage)) {
                        navigation(1); // Navigate forward on swipe right
                    }
                }
            }
        };

        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchend', handleTouchEnd);

        // Detect swipe direction on touch end
        if (touchEnd.x !== 0) {
            detectSwipeGesture();
        }

        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchend', handleTouchEnd);
        };
    }, [touchStart, touchEnd, isBlocking, navigation]);

    // Handle back button click (left arrow) and trigger confirmation
    const handleStopBlocking = (e) => {
        e.preventDefault(); // Prevent default link behavior
        const confirmationMessage = "Do you really want to leave this page?";
        if (window.confirm(confirmationMessage)) {
            navigation(-1); // Navigate back if user confirms
        }
    };

    // const handleActionClick = (action, subjectIndex, topicIndex, subTopicIndex) => {
    //     setActiveActions((prevState) => {
    //         const currentActions = prevState[subjectIndex]?.[topicIndex]?.[subTopicIndex] || [];

    //         // Toggle the action
    //         const newActions = currentActions.includes(action)
    //             ? currentActions.filter((a) => a !== action)
    //             : [...currentActions, action];

    //         return {
    //             ...prevState,
    //             [subjectIndex]: {
    //                 ...prevState[subjectIndex],
    //                 [topicIndex]: {
    //                     ...prevState[subjectIndex]?.[topicIndex],
    //                     [subTopicIndex]: newActions
    //                 }
    //             }
    //         };
    //     });
    //     setDropdownVisible(prevState => ({
    //         ...prevState,
    //         [`${subjectIndex}-${topicIndex}-${subTopicIndex}`]: !prevState[`${subjectIndex}-${topicIndex}-${subTopicIndex}`]
    //     }));
    // };
    // const handleDropdownToggle = (subjectIndex, topicIndex, subTopicIndex) => {
    //     const key = `${subjectIndex}-${topicIndex}-${subTopicIndex}`;
    //     setDropdownVisible(prevState => ({
    //         ...prevState,
    //         [`${subjectIndex}-${topicIndex}-${subTopicIndex}`]: !prevState[`${subjectIndex}-${topicIndex}-${subTopicIndex}`]
    //     }));
    //     console.log(dropdownVisible[key])
    // };

    const handleDropdownToggle = (subjectIndex, topicIndex, subTopicIndex) => {
        setShowTopicImg([subjectIndex, topicIndex, subTopicIndex]);  // Track the current subtopic
        const key = `${subjectIndex}-${topicIndex}-${subTopicIndex}`;
        setDropdownVisible(prevState => ({
            ...prevState,
            [key]: !prevState[key]
        }));
    };

    const handleActionClick = (action, subjectIndex, topicIndex, subTopicIndex) => {
        setShowTopicImg([subjectIndex, topicIndex, subTopicIndex]);  // Track the current subtopic

        setActiveActions(prevState => {
            const currentActions = prevState[subjectIndex]?.[topicIndex]?.[subTopicIndex] || [];

            const newActions = currentActions.includes(action)
                ? currentActions.filter(a => a !== action)
                : [...currentActions, action];

            return {
                ...prevState,
                [subjectIndex]: {
                    ...prevState[subjectIndex],
                    [topicIndex]: {
                        ...prevState[subjectIndex]?.[topicIndex],
                        [subTopicIndex]: newActions
                    }
                }
            };
        });

        setDropdownVisible(prevState => ({
            ...prevState,
            [`${subjectIndex}-${topicIndex}-${subTopicIndex}`]: !prevState[`${subjectIndex}-${topicIndex}-${subTopicIndex}`]
        }));
    };

    const handleCrossBtn = (action, subjectIndex, topicIndex, subTopicIndex) => {
        setActiveActions((prevState) => {
            const currentActions = prevState[subjectIndex]?.[topicIndex]?.[subTopicIndex] || [];

            // Toggle the action - this part is only about managing visibility
            const newActions = currentActions.includes(action)
                ? currentActions.filter((a) => a !== action)
                : [...currentActions, action];

            // If the action is being removed, delete the corresponding file_data entry
            if (currentActions.includes(action)) {
                setNewDemoData(prevData => prevData.map((subject, sIdx) => {
                    if (sIdx === subjectIndex) {
                        return {
                            ...subject,
                            topics: subject.topics.map((topic, tIdx) => {
                                if (tIdx === topicIndex) {
                                    return {
                                        ...topic,
                                        sub_topics: topic.sub_topics.map((subTopic, stIdx) => {
                                            if (stIdx === subTopicIndex) {
                                                // Delete the specific file_data entry for the action
                                                let updatedFileData = subTopic.file_data.filter(file => {
                                                    if (action === 'Teacher Notes') {
                                                        return file.teacher_note === ""; // Only keep entries that don't match this action
                                                    } else if (action === 'Pre-Requisites') {
                                                        return file.prerequisite === "";
                                                    } else if (action === 'Description') {
                                                        return file.description === "";
                                                    } else if (action === 'Conclusion') {
                                                        return file.conclusion === "";
                                                    } else if (action === 'Upload Files') {
                                                        return file.file_id !== null; // Keep files that still have a file_id
                                                    }
                                                    return true;
                                                });

                                                return {
                                                    ...subTopic,
                                                    file_data: updatedFileData, // Update the filtered array
                                                };
                                            }
                                            return subTopic;
                                        })
                                    };
                                }
                                return topic;
                            })
                        };
                    }
                    return subject;
                }));
            }

            // Update active actions state
            return {
                ...prevState,
                [subjectIndex]: {
                    ...prevState[subjectIndex],
                    [topicIndex]: {
                        ...prevState[subjectIndex]?.[topicIndex],
                        [subTopicIndex]: newActions
                    }
                }
            };
        });
    };


    // const handleVisibility = (action, topicIndex, subTopicIndex) => {
    //     console.log(topicIndex, subTopicIndex)
    //     setNewDemoData(prevData => 
    //         prevData.map((item, index) => {
    //           if (index === 0) { // Assuming you only have one main item
    //             return {
    //               ...item,
    //               topics: item.topics.map((topic, tIndex) => ({
    //                 ...topic,
    //                 sub_topics: topic.sub_topics.map((subTopic, sIndex) => 
    //                   tIndex === topicIndex && sIndex === subTopicIndex 
    //                   ? { 
    //                       ...subTopic, 
    //                       teacher_note_show: !subTopic.teacher_note_show // Toggle the value
    //                     } 
    //                   : subTopic
    //                 )
    //               }))
    //             }
    //           }
    //           return item
    //         })
    //       )
    //   }
    // const handleVisibility = (action, topicIndex, subTopicIndex) => {
    //     // Step 1: Toggle the active actions
    //     setActiveActions((prevActiveActions) => {
    //         if (prevActiveActions.includes(action)) {
    //             return prevActiveActions.filter((a) => a !== action);
    //         } else {
    //             return [...prevActiveActions, action];
    //         }
    //     });

    //     // Step 2: Toggle the visibility of teacher_note_show
    //     if (action === 'Teacher Notes') {  // Only toggle teacher_note_show when action is 'Teacher Notes'
    //         setNewDemoData(prevData =>
    //             prevData.map((item, index) => {
    //                 if (index === 0) { // Assuming you only have one main item
    //                     return {
    //                         ...item,
    //                         topics: item.topics.map((topic, tIndex) => ({
    //                             ...topic,
    //                             sub_topics: topic.sub_topics.map((subTopic, sIndex) =>
    //                                 tIndex === topicIndex && sIndex === subTopicIndex
    //                                     ? {
    //                                         ...subTopic,
    //                                         teacher_note_show: !subTopic.teacher_note_show // Toggle the value
    //                                     }
    //                                     : subTopic
    //                             )
    //                         }))
    //                     }
    //                 }
    //                 return item
    //             })
    //         )
    //     }

    //     // Optionally, hide dropdown after action is toggled
    //     setDropdownVisible(false);
    // }

    const headers = {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'Authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
    };

    // Function to toggle dropdown visibility
    // const handleDropdownToggle = (subjectIndex, topicIndex, subTopicIndex) => {
    //     setDropdownVisible(prevState => ({
    //         ...prevState,
    //         [`${subjectIndex}-${topicIndex}-${subTopicIndex}`]: !prevState[`${subjectIndex}-${topicIndex}-${subTopicIndex}`]
    //     }));
    // };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}/partner/curriculum-skill-table-data`, { headers });
                if (response.data.status === 'success') {
                    const formattedOptions = response.data.data.map(item => ({
                        value: item.id,
                        label: item.name
                    }));
                    setOptions(formattedOptions);
                } else {
                    console.log(response.data.message);
                }
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, []);

    // const handleChange = (selectedOptions) => {
    //     console.log('Selected options:', selectedOptions);
    // };
    const handleChange = (selectedOption, parentIndex, topicIndex, subTopicIndex) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            skill_id: selectedOption ? selectedOption.value : "" // Set the skill name (label) instead of the id
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData); // Update state
    };


    const initializeActiveActions = () => {

        const initialActions = {};

        newDemoData.forEach((subject, parentIndex) => {
            subject.topics.forEach((topic, topicIndex) => {
                topic.sub_topics.forEach((subTopic, subTopicIndex) => {
                    // Initialize active actions based on existing data
                    const actions = [];
                    if (subTopic.file_data.find(file => file.teacher_note !== "")) {
                        actions.push('Teacher Notes');
                    }
                    if (subTopic.file_data.find(file => file.prerequisite !== "")) {
                        actions.push('Pre-Requisites');
                    }
                    if (subTopic.file_data.find(file => file.description !== "")) {
                        actions.push('Description');
                    }
                    if (subTopic.file_data.find(file => file.conclusion !== "")) {
                        actions.push('Conclusion');
                    }

                    if (actions.length > 0) {
                        if (!initialActions[parentIndex]) initialActions[parentIndex] = {};
                        if (!initialActions[parentIndex][topicIndex]) initialActions[parentIndex][topicIndex] = {};
                        initialActions[parentIndex][topicIndex][subTopicIndex] = actions;
                    }
                });
            });
        });

        setActiveActions(initialActions);
    };


    useEffect(() => {
        if (editId) {
            initializeActiveActions();
        }
    }, [newDemoData]); // Runs when newDemoData changes

    // const handleActionClick = (action, index) => {
    //     setActiveActions((prevActiveActions) => {
    //         if (prevActiveActions.includes(action)) {
    //             return prevActiveActions.filter((a) => a !== action);
    //         } else {
    //             return [...prevActiveActions, action];
    //         }
    //     });
    //     setDropdownVisible(false); 
    // };
    // const isActive = (parentIndex, subTopicIndex) => {
    //     if (!newDemoData[parentIndex] || !newDemoData[parentIndex].topics || !newDemoData[parentIndex].topics[0].sub_topics[subTopicIndex]) {
    //       return false; // Return false if the structure is missing
    //     }
    //     console.log(newDemoData[parentIndex].topics[0].sub_topics[subTopicIndex].teacher_note_show)
    //     return newDemoData[parentIndex].topics[0].sub_topics[subTopicIndex].teacher_note_show;
    //   };
    //   const isActive = (action) => activeActions.includes(action);  
    const hasData = (parentIndex, topicIndex, subTopicIndex, field) => {
        return newDemoData[parentIndex]?.topics[topicIndex]?.sub_topics[subTopicIndex]?.file_data.find(file => file[field] !== "") !== undefined;
    };

    const isActive = (action, parentIndex, topicIndex, subTopicIndex) => {
        const actions = activeActions[parentIndex]?.[topicIndex]?.[subTopicIndex];
        return Array.isArray(actions) ? actions.includes(action) : false;  // Safely check if actions is an array
    };



    useEffect(() => {
        // Initialize the selectedType for each topic and subtopic
        const initialSelectedType = {};

        newDemoData.forEach((subject, subjectIndex) => {
            subject.topics.forEach((topic, topicIndex) => {
                // Set for topic
                const topicKey = `${subjectIndex}-${topicIndex}`;
                initialSelectedType[topicKey] = "Sub Topic";

                // Set for each sub_topic
                topic.sub_topics.forEach((subTopic, subTopicIndex) => {
                    const subTopicKey = `${subjectIndex}-${topicIndex}-${subTopicIndex}`;
                    initialSelectedType[subTopicKey] = "Sub Topic";
                });
            });
        });

        setSelectedType(initialSelectedType);
    }, []);  // This will run whenever newDemoData changes

    const handleSelectChange = (e, parentIndex, i, subIndex) => {
        const value = e.target?.value
        const key = `${parentIndex}-${i}-${subIndex}`;

        // Update the state with the new selected value
        setSelectedType(prevState => ({
            ...prevState,
            [key]: value
        }));
    };


    const getPlaceholder = (parentIndex, i, subIndex) => {
        const key = `${parentIndex}-${i}-${subIndex}`;
        return selectedType[key] === 'Sub Topic' ? 'Enter Sub Topic' : 'Add Activity';
    };

    // Handle toggle in subject tab visibility
    const toggleVisibility = (parentIndex) => {
        setVisibleTopics(prev => ({
            ...prev,
            [parentIndex]: !prev[parentIndex] // Toggle the visibility
        }));
    };

    // Subject curriclum preview code toggle
    const handleSubjectClick = (index) => {
        if (activeSubjects.includes(index)) {
            setActiveSubjects(activeSubjects.filter(i => i !== index)); // Collapse the subject
        } else {
            setActiveSubjects([...activeSubjects, index]); // Expand the subject
        }
    };

    // topic curriclum preview code toggle
    const handleTopicClick = (subjectIndex, topicIndex) => {
        const activeTopicsCopy = { ...activeTopics };
        if (!activeTopicsCopy[subjectIndex]) {
            activeTopicsCopy[subjectIndex] = [];
        }

        if (activeTopicsCopy[subjectIndex].includes(topicIndex)) {
            activeTopicsCopy[subjectIndex] = activeTopicsCopy[subjectIndex].filter(i => i !== topicIndex); // Collapse the topic
        } else {
            activeTopicsCopy[subjectIndex].push(topicIndex); // Expand the topic
        }

        setActiveTopics(activeTopicsCopy);
    };

    //HandleShow Add Image
    const handleTopicWiseImage = (arr) => {
        const cloneData = [...newDemoData];
        if (showTopicImg.length === 3) {
            const [subjectIndex, topicIndex, subTopicIndex] = showTopicImg;
            const existingFiles = cloneData[subjectIndex].topics[topicIndex].sub_topics[subTopicIndex].file_data || [];

            const newFiles = arr.map(file => ({
                file_id: file.file_id,
                mark_demo: "no",
                teacher_note: "",  // Leave empty as these fields shouldn't be overwritten
                description: "",
                prerequisite: "",
                conclusion: "",
                name: file.name,
                file_type: file.file_type,
                file_path: file.file_path,
            }));

            // Merge new files with existing file data without overwriting existing fields
            cloneData[subjectIndex].topics[topicIndex].sub_topics[subTopicIndex].file_data = [...existingFiles, ...newFiles];
        }

        setNewDemoData(cloneData);
        setIsFileModal(!isFileModal);
    };





    console.log(showTopicImg)
    // Handle to get Class Dropdown
    const handleGetClassDropdown = () => {
        axios.get(`${baseUrl}/partner/class-dropdown`, {
            headers,
        })
            .then((res) => {
                const response = res.data;
                if (response.status === "success") {
                    const data = response.data;
                    setClassDropdown(data)
                } else {
                    console.log(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleGetCategoryDropdown = () => {
        axios.get(`${baseUrl}/partner/curriculum-category-dropdown`, {
            headers,
        })
            .then((res) => {
                const response = res.data;
                if (response.status === "success") {
                    const data = response.data;
                    setCategoryDropdown(data)
                } else {
                    console.log(response.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const handleUpdate = (draftId) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-update`, {
            title,
            description,
            category_id: selectCate,
            curriculum_type: content_status ? "1" : "2",
            status: draftId ? draftId : draftStatus,
            content_data: JSON.stringify(newDemoData),
            class_id: classId,
            auth_bearer_token: authentication_key,
            id: editId
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                toast.success("Successfully uploaded")
                navigation("/modules/curriculum")
                handleReset()
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }
    const handleAddCurriculum = (draftId) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-store`, {
            title,
            description,
            category_id: selectCate,
            curriculum_type: content_status ? "1" : "2",
            status: draftId ? draftId : draftStatus,
            class_id: classId,
            content_data: JSON.stringify(newDemoData),
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                toast.success("Successfully uploaded")
                navigation("/modules/curriculum")
                handleReset()
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }
    const handleCheck = () => {
        let checked = true
        const contentData = [...newDemoData]
        contentData?.forEach(element => {
            if (element.name?.length > 0) {
                element.topics?.forEach(io => {
                    if (!io.name?.length > 0 && checked) {
                        toast.error("Please fill the topic name")
                        checked = false
                    }
                })
            } else if (checked) {
                toast.error("Please fill the part name")
                checked = false
            }
        })
        return checked
    }
    const handleSubmit = (draftId) => {
        if (handleCheck()) {
            if (editId) {
                handleUpdate(draftId)
            } else {
                handleAddCurriculum(draftId)
            }
        }
    }

    const handleAddSubject = (parentIndex, index, type) => {
        // const newData = [{ value: "", list: [] }]
        if (type === "sub_topices") {
            const newData = newDemoData?.map((io, i) => {
                if (i === parentIndex) {
                    io["topics"] = io.topics?.map((elm, ind) => {
                        if (ind === index) {
                            elm["sub_topics"] = editId ? [...elm.sub_topics, { name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] : [...elm.sub_topics, { name: "", topic_days: 0, topic_grace: 0, file_data: [] }]
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newData)
        } else {
            const data = newDemoData?.map((io, i) => {
                if (i === parentIndex) {
                    io["topics"] = editId ? [...io.topics, { name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] }] : [...io.topics, { name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", sub_topic_type: 2, topic_days: 0, topic_grace: 0, file_data: [] }] }]
                }
                return io
            })
            setNewDemoData(data)
        }
    }

    const handleRemoveSubject = (parentIndex, index, type, sub_index) => {
        if (type === "sub-subject") {
            const newData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    if (io.topics?.length > 1) {
                        io["topics"] = io.topics?.filter((io, subIndex) => subIndex !== index)
                    }
                }
                return io
            })
            setNewDemoData(newData)
        } else if (type === "subject") {
            const data = newDemoData?.filter((io, i) => parentIndex !== i)
            setNewDemoData(data)
        } else if (type === "sub-sub_topices") {
            const subData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    io["topics"] = io.topics?.map((ele, subIndex) => {
                        if (index === subIndex) {
                            if (ele.sub_topics?.length > 1) {
                                ele["sub_topics"] = ele.sub_topics?.filter((io, Ind) => Ind !== sub_index)
                            }
                        }
                        return ele
                    })
                }
                return io
            })
            setNewDemoData(subData)
        }
        setShowTopicImg([])
    }
    ///Handle INput 
    const handleInputTextBox = (value, parentIndex, subIndex, type) => {
        if (type === "subject") {
            const newSubjData = newDemoData?.map((io, i) => {
                if (parentIndex === i) {
                    io["name"] = value
                }
                return io
            })
            console.log(newSubjData, "newSubjData")
            setNewDemoData(newSubjData)
        } else if (type === "sub-subject") {
            const newSubData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm["name"] = value
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newSubData)
        } else if (type === "sub-sub-subject") {
            const newSubData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm["sub_topics"] = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["name"] = value
                                }
                                return element
                            })
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(newSubData)
        } else if (type === "description") {
            const newDescData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["description"] = value; // Update description
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newDescData);
        } else if (type === "prerequisites") {
            const newPrereqData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["prerequisites"] = value; // Update prerequisites
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newPrereqData);
        }
        else if (type === "conclusion") {
            const newPrereqData = newDemoData?.map((io, index) => {
                if (parentIndex === index) {
                    io["topics"] = io.topics?.map((elm, i) => {
                        if (subIndex === i) {
                            elm.sub_topics = elm.sub_topics?.map((element, sub_index) => {
                                if (showTopicImg[2] === sub_index) {
                                    element["conclusion"] = value; // Update conclusion
                                }
                                return element;
                            });
                        }
                        return elm;
                    });
                }
                return io;
            });
            setNewDemoData(newPrereqData);
        }
    }
    const handleInputTextBoxNew = (value, parentIndex, topicIndex, subTopicIndex, fieldType, markAsDemo = false) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        // Check if there is already an entry for this field in file_data
                                        let existingEntry = subTopic.file_data.find(file => file[fieldType] !== "");

                                        // If no existing entry for this field, create a new one
                                        if (!existingEntry) {
                                            existingEntry = {
                                                mark_demo: "",
                                                teacher_note: "",
                                                description: "",
                                                prerequisite: "",
                                                conclusion: "",
                                                file_type: "text",
                                                [fieldType]: value  // Add the value to the correct field
                                            };
                                            subTopic.file_data.push(existingEntry); // Add entry in order
                                        } else {
                                            // If there's an entry, update the existing one
                                            existingEntry[fieldType] = value;
                                        }
                                        if (markAsDemo) {
                                            existingEntry.mark_demo = existingEntry.mark_demo === "yes" ? "no" : "yes";
                                        }
                                        // Ensure file_data is updated without sorting to preserve order
                                        return {
                                            ...subTopic,
                                            file_data: [...subTopic.file_data]  // Keep the original order
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);  // Update the state
    };

    const handleAddNewPart = () => {
        if (editId) {
            setNewDemoData([...newDemoData, { name: "", id: 0, topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
        } else {
            setNewDemoData([...newDemoData, { name: "", topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
        }
    }
    const handleDeleteTopic = (id, pInd, index, type) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-topic-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                // setFlag(!flag)
                handleRemoveSubject(pInd, index, type)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }

    const handleDeleteSubTopic = (id, pIndex, tIndex, type, sTIndex) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-subtopic-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                handleRemoveSubject(pIndex, tIndex, type, sTIndex)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })
    }

    const handleDeleteFile = (id) => {
        setLoad(true)
        axios.postForm(`${baseUrl}/partner/curriculum-content-file-destroy`, {
            id,
            curriculum_id: editId,
            auth_bearer_token: authentication_key
        }, {
            headers: {
                'Content-Type': 'application/json', // Set the content type based on your API requirements
                'authentication_key': authentication_key, // Include any authorization token if needed
                'Authorization': 'Bearer ' + authentication_key,
                // Add other headers as needed
            }
        }).then(res => {
            const data = res.data
            if (data.status === "success") {
                setFlag(!flag)
            } else {
                toast.error(data.message)
            }
            setLoad(false)
        }
        ).catch(err => {
            setLoad(false)
            console.log(err)
        })
    }

    function handleDropdown(countryName) {
        setCountry(countryName)
    }

    function handleSubjectInput(e) {
        const newArray = [...subjectArray];
        newArray.push("");
        setSubjectArray(newArray);
    }

    function handleCurriculamState() {
        if (newCurriculamState === 'basic-details') {
            if (isActiveNext) {
                setNewCurriculamState('content')
            }
        } else if (newCurriculamState === 'content') {
            setNewCurriculamState('basic-details')
        }
    }

    function handleFiles(e) {
        setAddFiles(!addFiles)
    }
    const removeContentCard = (index, type) => {
        if (showTopicImg?.length >= 2) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io.topics?.map((elm, ind) => {
                        if (type === "sub_topic" && ind === showTopicImg[1]) {
                            elm["sub_topics"] = elm.sub_topics.map((element, subIndex) => {
                                if (subIndex === showTopicImg[2]) {
                                    element["file_data"] = element.file_data?.filter((element, indx) => indx !== index)
                                }
                                return element
                            })
                        } else {
                            if (ind === showTopicImg[1]) {
                                elm["file_data"] = elm.file_data?.filter((element, indx) => indx !== index)
                            }
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }
    }

    const handleDemoContent = (index, type) => {
        const builkCheck = newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.filter(io => io.mark_demo === "yes")?.length === newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.length
        if (showTopicImg?.length === 2) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io.topics?.map((elm, ind) => {
                        if (ind === showTopicImg[1]) {
                            elm["file_data"] = elm.file_data?.map((element, indx) => {
                                if (type === "bulk") {
                                    element["mark_demo"] = builkCheck ? "no" : "yes"
                                } else if (indx === index) {
                                    element["mark_demo"] = element.mark_demo === "no" ? "yes" : "no"
                                }
                                return element
                            })
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }
    }
    const handleDemoContentSubTopic = (index, type) => {
        const builkCheck = newDemoData[showTopicImg[0]].topics[showTopicImg[1]].sub_topics[showTopicImg[2]].file_data?.filter(io => io.mark_demo === "yes")?.length === newDemoData[showTopicImg[0]].topics[showTopicImg[1]].sub_topics[showTopicImg[2]].file_data.length
        if (showTopicImg?.length === 3) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io["topics"] = io.topics?.map((elm, ind) => {
                        if (ind === showTopicImg[1]) {
                            elm["sub_topics"] = elm.sub_topics?.map((sub_elm, sub_index) => {
                                if (sub_index === showTopicImg[2]) {
                                    sub_elm["file_data"] = sub_elm.file_data?.map((element, indx) => {
                                        if (type === "bulk") {
                                            element["mark_demo"] = builkCheck ? "no" : "yes"
                                        } else if (indx === index) {
                                            element["mark_demo"] = element.mark_demo === "no" ? "yes" : "no"
                                        }
                                        return element
                                    })
                                }
                                return sub_elm
                            })
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }
    }
    const handleGetEditData = (id) => {
        setLoad(true)
        const payload = {
            id,
            auth_bearer_token: authentication_key
        }
        axios.get(`${baseUrl}/partner/curriculum-edit`, {
            headers,
            params: payload
        }).then(res => {
            const response = res.data
            if (response.status === "success") {
                const data = response.data
                if (data) {
                    setTitle(data.title)
                    setDescription(data.description)
                    setSelectCate(data.category_id)
                    setClassId(data.class_id)
                    setContent_status(data.curriculum_type === "1")
                    if (data.content_data) {
                        const content_data = data.content_data.map(sub => {
                            if (sub.topics && Array.isArray(sub.topics) && sub.topics.length > 0) {
                                sub["topics"] = sub.topics.map(topic => {
                                    if (!topic.sub_topics || !Array.isArray(topic.sub_topics) || topic.sub_topics.length === 0) {
                                        topic["sub_topics"] = [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }]
                                    }
                                    return topic
                                })
                            } else {
                                sub["topics"] = [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", id: 0, topic_days: 0, topic_grace: 0, file_data: [] }] }]
                            }
                            return sub
                        })
                        setNewDemoData(content_data)
                    }
                }
                setLoad(false)
            } else {
                console.log(response.message)
                setLoad(false)
            }
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })

    }

    const handleSubTopicClick = (parentIndex, topicIndex, subTopicIndex) => {
        setActiveSubTopic(`${parentIndex}-${topicIndex}-${subTopicIndex}`);
    };

    const handleIncrese = (type, parentIndex, topicIndex, subTopicIndex) => {
        setNewDemoData(prevData => prevData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            topic_days: Number(subTopic.topic_days) + 1 // Ensure numeric addition
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        }));
    };


    const handleDecrease = (type, parentIndex, topicIndex, subTopicIndex) => {
        const updatedData = newDemoData.map((subject, pIndex) => {
            if (pIndex === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIndex) => {
                        if (tIndex === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIndex) => {
                                    if (sIndex === subTopicIndex && subTopic.topic_days > 0) {
                                        return {
                                            ...subTopic,
                                            topic_days: subTopic.topic_days - 1
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });
        setNewDemoData(updatedData);
    };

    const handleDaysInput = (value, type, sub_type) => {
        const numberRegex = /^[0-9]+(\.[0-9]+)?$/
        if (numberRegex.test(value) || !value) {
            const data = newDemoData?.map((io, i) => {
                if (i === showTopicImg[0]) {
                    io.topics?.map((elm, ind) => {
                        if (ind === showTopicImg[1]) {
                            if (sub_type && sub_type === "sub_topics") {
                                elm.sub_topics?.map((element, index) => {
                                    if (index === showTopicImg[2]) {
                                        if (type === "topic_days") {
                                            element["topic_days"] = value ? +value : 0
                                        } else {
                                            element["topic_grace"] = value ? +value : 0
                                        }
                                    }
                                    return element
                                })
                            } else {
                                if (type === "topic_days") {
                                    elm["topic_days"] = value ? +value : 0
                                } else {
                                    elm["topic_grace"] = value ? +value : 0
                                }
                            }
                        }
                        return elm
                    })
                }
                return io
            })
            setNewDemoData(data)
        }

    }
    const handleToggleChange = (parentIndex, topicIndex, subTopicIndex, field) => {
        const updatedData = newDemoData.map((subject, pIdx) => {
            if (pIdx === parentIndex) {
                return {
                    ...subject,
                    topics: subject.topics.map((topic, tIdx) => {
                        if (tIdx === topicIndex) {
                            return {
                                ...topic,
                                sub_topics: topic.sub_topics.map((subTopic, sIdx) => {
                                    if (sIdx === subTopicIndex) {
                                        return {
                                            ...subTopic,
                                            [field]: subTopic[field] === "1" ? "2" : "1" // Toggle between 1 and 2
                                        };
                                    }
                                    return subTopic;
                                })
                            };
                        }
                        return topic;
                    })
                };
            }
            return subject;
        });

        setNewDemoData(updatedData);
    };
    const handleDeleteConfirmation = (id, parentIndex, topicIndex, subTopicType) => {
        confirmAlert({
            title: "Confirm delete",
            message: 'Do you really want to delete this topic?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        if (editId && elm.id) {
                            handleDeleteSubTopic(id, parentIndex, topicIndex, subTopicType);
                        } else {
                            handleRemoveSubject(parentIndex, topicIndex, subTopicType);
                        }
                    },
                },
                {
                    label: 'No',
                    onClick: () => { },
                },
            ],
        });
    };

    const handleReset = () => {
        if (editId) {
            handleGetEditData(editId)
        } else {
            setNewDemoData([{ name: "", topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [], sub_topics: [{ name: "", topic_days: 0, topic_grace: 0, file_data: [] }] }] }])
            setTitle("")
            setSelectCate("")
            setDescription("")
        }
    }

    useEffect(() => {
        if (title && description && selectCate && !isActiveNext && classId) {
            setIsActiveNext(true)
        } else if ((!title || !description || !selectCate || !classId) && isActiveNext) {
            setIsActiveNext(false)
        }
    }, [title, description, selectCate])
    useEffect(() => {
        const state = location.state
        if (state && state.id) {
            setEditId(state.id)
            handleGetEditData(state.id)
        } else {
            setEditId("")
        }
    }, [flag])
    useEffect(() => {
        handleGetClassDropdown()
        handleGetCategoryDropdown()
    }, [])

    return (
        <div>
            <div className='d-flex align-items-center gap justify-content-between p-2 mx-2' >
                <div className='d-flex align-items-center gap'>
                    <div onClick={(e) => handleStopBlocking(e)} style={{ cursor: "pointer" }} >
                        <img src={leftArrow} />
                    </div>
                    <div className='page-heading'>
                        {editId ? "Edit " : "Add "} Curriculum
                    </div>
                </div>
                {/* <ButtonComponent buttonType={'secondary'} title={'Take A Tour'} /> */}
            </div>

            <div className='m-4 ' >
                <div className='d-flex main_curriculum_section'>
                    <div className='new-curriculam-first-part-section' >
                        <div>
                            <div className='d-flex basic-details-content-div'>
                                <span className='main_curriculum_tab' onClick={e => handleCurriculamState()} style={{ color: newCurriculamState === 'basic-details' ? '#0258a1' : '#C4C4C4', borderBottom: newCurriculamState === 'basic-details' ? '4px solid #0258a1' : '#C4C4C4', cursor: 'pointer' }} >Basic Details</span>
                                <span className='main_curriculum_tab' onClick={e => handleCurriculamState()} style={{ color: newCurriculamState === 'content' ? '#0258a1' : '#C4C4C4', borderBottom: newCurriculamState === 'content' ? '4px solid #0258a1' : '#C4C4C4', cursor: 'pointer' }}>Content</span>
                            </div>
                            {
                                newCurriculamState === 'basic-details' ? <div className='p-3 details-section d-flex flex-column gap'>
                                    {load ? (<>
                                        <SpinnerComponent />
                                    </>) : (<>
                                        <CustomInput
                                            placeholder={'Enter Title'}
                                            className={'w-100 curriclam-side-input-section'}
                                            onChange={(e) => setTitle(e.target.value)}
                                            value={title}
                                        />
                                        <textarea
                                            class="form-control"
                                            id="exampleFormControlTextarea1"
                                            rows="6"
                                            placeholder='Enter Description'
                                            value={description}
                                            onChange={(e) => setDescription(e.target.value)}
                                        />
                                        <DropDown
                                            options={classDropdown}
                                            placeholder="Select Class"
                                            value={classDropdown?.filter(elm => elm.id === classId)}
                                            onChange={(e) => setClassId(e && e.id ? e.id : "")}
                                            isClearable={true}
                                            id={'selectClass'}
                                        />
                                        <DropDown
                                            options={categoryDropdown}
                                            placeholder="Select Category"
                                            value={categoryDropdown?.filter(elm => elm.id === selectCate)}
                                            onChange={(e) => setSelectCate(e && e.id ? e.id : "")}
                                            isClearable={true}
                                            id={'selectCategory'}
                                        />
                                    </>)}
                                </div> : (<>
                                    <div>
                                        {load ? (<>
                                            <SpinnerComponent />
                                        </>) : (<>
                                            <div>
                                                {newDemoData?.length > 0 ? newDemoData?.map((element, parentIndex) => {
                                                    return (
                                                        <div className='main_add_subject_section' key={`subject-${parentIndex}`}>
                                                            <div className={`d-flex align-items-center mx-2 my-2 curriculam-side-subject-section curriculam-side-subject-cusInput chapter ${isInputFocused === `${parentIndex}subject-new-main` ? "curriculam-side-subject-cusInput-active" : ""}`}>
                                                                <div className='subject_counter'><strong>{parentIndex + 1}</strong></div>
                                                                <input
                                                                    placeholder={'Add Subject'}
                                                                    className={'w-100'}
                                                                    onChange={(e) => handleInputTextBox(e.target.value, parentIndex, null, "subject")}
                                                                    value={element.name}
                                                                    onFocus={() => { setIsInputFocused(`${parentIndex}subject-new-main`); setShowTopicImg([]) }}

                                                                    onBlur={() => { setIsInputFocused(""); setShowTopicImg([]) }}
                                                                    onClick={() => toggleVisibility(parentIndex)}
                                                                />
                                                                {newDemoData?.length > 1 && <div className='trash2-icon' onClick={() => handleRemoveSubject(parentIndex, null, "subject")}>
                                                                    <Trash2 style={{ cursor: 'pointer' }} />
                                                                </div>}
                                                            </div>
                                                            {visibleTopics[parentIndex] && element.topics?.map((elm, i) => (
                                                                <React.Fragment key={`topic-${parentIndex}-${i}`}>
                                                                    <div className={`d-flex align-items-center mx-2 mb-2 ${parentIndex === 0 ? 'mt-2' : ""}`} style={{border: "1px solid #C0C0C0", borderRadius:"4px"}}>
                                                                        <div className='icon-menu-container' onClick={() => handleAddSubject(parentIndex, i)}>
                                                                            <Plus />
                                                                        </div>
                                                                        <div className={`d-flex align-items-center w-100 curriculam-side-subject-section curriculam-side-subject-cusInput ${showTopicImg?.length > 0 && showTopicImg[0] === parentIndex && showTopicImg[1] === i && isInputFocused === `${i}subject` ? "curriculam-side-subject-cusInput-active" : ""}`}>
                                                                            <div className="topic_counter"><strong>{parentIndex + 1}.{i + 1}</strong></div> {/* Topic Counter */}
                                                                            <input
                                                                                placeholder={'Add Topic'}
                                                                                className={'w-100'}
                                                                                onChange={(e) => handleInputTextBox(e.target.value, parentIndex, i, "sub-subject")}
                                                                                value={elm.name}
                                                                                onFocus={() => { setIsInputFocused(`${i}subject`); setShowTopicImg([parentIndex, i]) }}
                                                                            />
                                                                            {element.topics.length > 1 && <div className='trash2-icon' onClick={() => handleDeleteConfirmation(elm.id, parentIndex, i, "sub-subject")}>
                                                                                <Trash2 style={{ cursor: 'pointer' }} />
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                    {showTopicImg?.length === 2 && (
                                                                        <div className='add_topics_padding'>
                                                                            <div className='d-flex align-items-center flex-wrap'>
                                                                                {/* <CustomInputWithIncDec
                                                                                    title="Grace"
                                                                                    isPlusMinus={false}
                                                                                    value={newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_grace ? newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_grace : 0}
                                                                                    onChange={(e) => handleDaysInput(e.target.value)}
                                                                                /> */}
                                                                                {/* <CustomInputWithIncDec
                                                                                    title="Days"
                                                                                    style={{ marginLeft: '5px' }}
                                                                                    value={newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_days ? newDemoData[showTopicImg[0]].topics[showTopicImg[1]].topic_days : 0}
                                                                                    onClickAdd={() => handleIncrese()}
                                                                                    onClickMinus={() => handleDecrease()}
                                                                                    onChange={(e) => handleDaysInput(e.target.value, "topic_days")}
                                                                                /> */}
                                                                                {/* <div style={{ marginLeft: '5px' }}>
                                                                                    <SelectedButton
                                                                                        onClick={() => handleDemoContent("", "bulk")}
                                                                                        buttonType={newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.filter(io => io.mark_demo === "yes")?.length === newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.length ? "primary-checked" : "secondary"}
                                                                                    />
                                                                                </div>*/}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {elm.sub_topics?.map((sub_elm, subIndex) => (
                                                                        <React.Fragment key={`sub-topic-${parentIndex}-${i}-${subIndex}`}>
                                                                            <div className={`d-flex align-items-center sub-topic-div mb-2 ${parentIndex === 0 ? 'mt-2' : ""}`}>
                                                                                <div className='icon-menu-container' onClick={() => handleAddSubject(parentIndex, i, "sub_topices")}>
                                                                                    <Plus />
                                                                                </div>
                                                                                <div className={`d-flex align-items-center curriculam-side-subject-section curriculam-side-subject-cusInput ${showTopicImg?.length > 0 && showTopicImg[0] === parentIndex && showTopicImg[1] === i && showTopicImg[2] === subIndex ? "curriculam-side-subject-cusInput-active" : ""}`} style={{width:"94%", border:"1px solid #C0C0C0"}}>
                                                                                    <div className="sub_topic_counter"><strong>{parentIndex + 1}.{i + 1}.{subIndex + 1}</strong></div> {/* Sub-Topic Counter */}
                                                                                    <input
                                                                                        placeholder={getPlaceholder(parentIndex, i, subIndex)}
                                                                                        className={'w-100'}
                                                                                        onChange={(e) => handleInputTextBox(e.target.value, parentIndex, i, "sub-sub-subject")}
                                                                                        value={sub_elm.name}
                                                                                        onFocus={() => { setIsInputFocused(`${subIndex}sub_subject`); setShowTopicImg([parentIndex, i, subIndex]); handleSubTopicClick(parentIndex, i, subIndex); }}
                                                                                    />
                                                                                    <select className='choose_subtopic_activity' value={selectedType[`${parentIndex}-${i}-${subIndex}`] || 'Sub Topic'} onChange={(e) => handleSelectChange(e, parentIndex, i, subIndex)}>
                                                                                        <option value='Sub Topic'>Sub Topic</option>
                                                                                        <option value='Acitivity'>Activity</option>
                                                                                    </select>
                                                                                    <div className="add-action-container">
                                                                                        <button className="add-actions-btn" onClick={() => handleDropdownToggle(parentIndex, i, subIndex)}>
                                                                                            <Plus size={16} /> Add Actions
                                                                                        </button>
                                                                                        {dropdownVisible[`${parentIndex}-${i}-${subIndex}`] === true && (
                                                                                            <div className='dropdown-menu'>
                                                                                                <div className={`dropdown-item ${isActive('Teacher Notes', parentIndex, i, subIndex) ? 'active' : ''}`} onClick={() => handleActionClick('Teacher Notes', parentIndex, i, subIndex)}>Teacher Notes</div>
                                                                                                <div className={`dropdown-item ${isActive('Pre-Requisites', parentIndex, i, subIndex) ? 'active' : ''}`} onClick={() => handleActionClick('Pre-Requisites', parentIndex, i, subIndex)}>Pre-Requisites</div>
                                                                                                <div className={`dropdown-item ${isActive('Description', parentIndex, i, subIndex) ? 'active' : ''}`} onClick={() => handleActionClick('Description', parentIndex, i, subIndex)}>Description</div>
                                                                                                <div className={`dropdown-item ${isActive('Conclusion', parentIndex, i, subIndex) ? 'active' : ''}`} onClick={() => handleActionClick('Conclusion', parentIndex, i, subIndex)}>Conclusion</div>
                                                                                                <div className={`dropdown-item ${isActive('Upload Files', parentIndex, i, subIndex) ? 'active' : ''}`} onClick={() => handleActionClick('Upload Files', parentIndex, i, subIndex)}>Upload Files</div>

                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                    {elm.sub_topics.length > 1 && <div className='trash2-icon' onClick={() => { editId && sub_elm.id ? handleDeleteSubTopic(sub_elm.id, parentIndex, i, "sub-sub_topices", subIndex) : handleRemoveSubject(parentIndex, i, "sub-sub_topices", subIndex) }}>
                                                                                        <Trash2 style={{ cursor: 'pointer' }} />
                                                                                    </div>}
                                                                                </div>
                                                                            </div>
                                                                            <div className='add_topics_items'>
                                                                                {activeSubTopic === `${parentIndex}-${i}-${subIndex}` && (

                                                                                    <div className='d-flex align-items-center mb-2 gap-2'>
                                                                                        {/* Days Input */}
                                                                                        <CustomInputWithIncDec
                                                                                            title="Days"
                                                                                            value={newDemoData[parentIndex].topics[i].sub_topics[subIndex].topic_days || 0}
                                                                                            onClickAdd={() => handleIncrese("sub_topic", parentIndex, i, subIndex)}
                                                                                            onClickMinus={() => handleDecrease("sub_topic", parentIndex, i, subIndex)}
                                                                                            onChange={(e) => handleDaysInput(e.target.value, "topic_days", "sub_topics")}
                                                                                        />

                                                                                        {/* Skills Dropdown */}
                                                                                        <Select
                                                                                            className='multiselect_custom_css'
                                                                                            options={options}
                                                                                            isClearable={true}
                                                                                            onChange={(selectedOption) => handleChange(selectedOption, parentIndex, i, subIndex)}
                                                                                            placeholder="Select skills"
                                                                                            value={options.find(option => option.value === newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.skill_id) || null}
                                                                                        />

                                                                                        {/* Diary Note Toggle */}
                                                                                        <div className="toggle-div-style font-adjust">
                                                                                            <Toggle
                                                                                                defaultChecked={newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.diary_note === "1"}
                                                                                                onChange={() => handleToggleChange(parentIndex, i, subIndex, 'diary_note')}
                                                                                                icons={{
                                                                                                    checked: "Dairy Note",
                                                                                                    unchecked: "Diary Note"
                                                                                                }}
                                                                                            />
                                                                                        </div>

                                                                                        {/* Gallery Toggle */}
                                                                                        <div className="toggle-div-style font-adjust-gallery">
                                                                                            <Toggle
                                                                                                defaultChecked={newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.gallery === "1"}
                                                                                                onChange={() => handleToggleChange(parentIndex, i, subIndex, 'gallery')}
                                                                                                icons={{
                                                                                                    checked: "Gallery",
                                                                                                    unchecked: "Gallery"
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                                <div className='editor-section1'>
                                                                                    {isActive('Upload Files', parentIndex, i, subIndex) &&
                                                                                        <>
                                                                                            {/* <h4 className='editor_text'>Add Files</h4> */}
                                                                                            <div className='d-flex gap-4 align-items-center mb-4'>
                                                                                                <ButtonComponent buttonType={'primary'} title={'Add Files'} onClick={e => setIsFileModal(!isFileModal)} />
                                                                                                <div className='media-link-icon-container' onClick={() => setMediaLinkModalOpen(true)}>
                                                                                                    <Link2 color='#838383' />
                                                                                                </div>
                                                                                            </div>
                                                                                        </>}
                                                                                    {isActive('Teacher Notes', parentIndex, i, subIndex) && (
                                                                                        <>
                                                                                            <div class="text-box mb-4">
                                                                                                <div class="header">
                                                                                                    <h3>Teacher Notes</h3>
                                                                                                    <div class="actions">
                                                                                                        <button className={`mark-demo ${newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.teacher_note !== "" && file.mark_demo === 'yes') ? 'active-demo' : 'inactive-demo'}`}
                                                                                                            onClick={() => handleInputTextBoxNew(
                                                                                                                newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.teacher_note !== "")?.teacher_note || "",
                                                                                                                parentIndex, i, subIndex, 'teacher_note', true // Pass `true` to mark as demo
                                                                                                            )}
                                                                                                            disabled={
                                                                                                                !newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.teacher_note !== "")?.teacher_note
                                                                                                            }
                                                                                                        >
                                                                                                            <span>✔</span> Mark As Demo
                                                                                                        </button>
                                                                                                        <button
                                                                                                            class="close-btn"
                                                                                                            onClick={() => handleCrossBtn('Teacher Notes', parentIndex, i, subIndex)} // Clear the data
                                                                                                        >
                                                                                                            <XCircle />
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className='text-box'>
                                                                                                    <textarea
                                                                                                        className='form-control'
                                                                                                        rows='6'
                                                                                                        value={
                                                                                                            newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.teacher_note !== "")?.teacher_note || ""
                                                                                                        }
                                                                                                        onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'teacher_note')}
                                                                                                        placeholder="Teacher Notes"
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    {isActive('Pre-Requisites', parentIndex, i, subIndex) && (
                                                                                        <>
                                                                                            <div className="text-box mb-4">
                                                                                                <div className="header">
                                                                                                    <h3>Pre-Requisites</h3>
                                                                                                    <div className="actions">
                                                                                                        <button
                                                                                                            className={`mark-demo ${newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.prerequisite !== "" && file.mark_demo === 'yes') ? 'active-demo' : 'inactive-demo'}`}
                                                                                                            onClick={() => handleInputTextBoxNew(
                                                                                                                newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.prerequisite !== "")?.prerequisite || "",
                                                                                                                parentIndex, i, subIndex, 'prerequisite', true
                                                                                                            )}
                                                                                                            disabled={
                                                                                                                !newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.prerequisite !== "")?.prerequisite
                                                                                                            }
                                                                                                        >
                                                                                                            <span>✔</span> Mark As Demo
                                                                                                        </button>
                                                                                                        <button className="close-btn"
                                                                                                            onClick={() => handleCrossBtn('Pre-Requisites', parentIndex, i, subIndex)}
                                                                                                        ><XCircle /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className='text-box'>
                                                                                                    <textarea
                                                                                                        className='form-control'
                                                                                                        rows='6'
                                                                                                        value={
                                                                                                            newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.prerequisite !== "")?.prerequisite || ""
                                                                                                        }
                                                                                                        onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'prerequisite')}
                                                                                                        placeholder="Pre-Requisites"
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    {isActive('Description', parentIndex, i, subIndex) && (
                                                                                        <>
                                                                                            <div className="text-box mb-4">
                                                                                                <div className="header">
                                                                                                    <h3>Description</h3>
                                                                                                    <div className="actions">
                                                                                                        <button
                                                                                                            className={`mark-demo ${newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.description !== "" && file.mark_demo === 'yes') ? 'active-demo' : 'inactive-demo'}`}
                                                                                                            onClick={() => handleInputTextBoxNew(
                                                                                                                newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.description !== "")?.description || "",
                                                                                                                parentIndex, i, subIndex, 'description', true
                                                                                                            )}
                                                                                                            disabled={
                                                                                                                !newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.description !== "")?.description
                                                                                                            }
                                                                                                        >
                                                                                                            <span>✔</span> Mark As Demo
                                                                                                        </button>
                                                                                                        <button className="close-btn"><XCircle /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className='text-box'>
                                                                                                    <textarea
                                                                                                        className='form-control'
                                                                                                        rows='6'
                                                                                                        value={
                                                                                                            newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.description !== "")?.description || ""
                                                                                                        }
                                                                                                        onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'description')}
                                                                                                        placeholder="Description"
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </>
                                                                                    )}

                                                                                    {isActive('Conclusion', parentIndex, i, subIndex) && (
                                                                                        <>
                                                                                            <div className="text-box mb-4">
                                                                                                <div className="header">
                                                                                                    <h3>Conclusion</h3>
                                                                                                    <div className="actions">
                                                                                                        <button
                                                                                                            className={`mark-demo ${newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.conclusion !== "" && file.mark_demo === 'yes') ? 'active-demo' : 'inactive-demo'}`}
                                                                                                            onClick={() => handleInputTextBoxNew(
                                                                                                                newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.conclusion !== "")?.conclusion || "",
                                                                                                                parentIndex, i, subIndex, 'conclusion', true
                                                                                                            )}
                                                                                                            disabled={
                                                                                                                !newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.conclusion !== "")?.conclusion
                                                                                                            }
                                                                                                        >
                                                                                                            <span>✔</span> Mark As Demo
                                                                                                        </button>
                                                                                                        <button className="close-btn"><XCircle /></button>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <p className='text-box'>
                                                                                                    <textarea
                                                                                                        className='form-control'
                                                                                                        rows='6'
                                                                                                        value={
                                                                                                            newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.find(file => file.conclusion !== "")?.conclusion || ""
                                                                                                        }
                                                                                                        onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'conclusion')}
                                                                                                        placeholder="Conclusion"
                                                                                                    />
                                                                                                </p>
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>

                                                                                {/* {editId && (
                                                                                    <div className='editor-section2'>
                                                                                        {newDemoData[parentIndex]?.topics[i]?.sub_topics[subIndex]?.file_data.map((item, itemIndex) => {
                                                                                            // If it is a text-based file (file_type is "text" or null)
                                                                                            if (item.file_type === 'text' || !item.file_type) {
                                                                                                return (
                                                                                                    <div key={itemIndex} className="editor-item">
                                                                                                        {isActive('Teacher Notes', parentIndex, i, subIndex) && item.teacher_note !== "" && (
                                                                                                            <>
                                                                                                                <h4 className='editor_text'>Teacher Notes</h4>
                                                                                                                <textarea
                                                                                                                    className='form-control'
                                                                                                                    rows='6'
                                                                                                                    value={item.teacher_note}
                                                                                                                    onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'teacher_note')}
                                                                                                                    placeholder="Teacher Notes"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {isActive('Description', parentIndex, i, subIndex) && item.description !== "" && (
                                                                                                            <>
                                                                                                                <h4 className='editor_text'>Description</h4>
                                                                                                                <textarea
                                                                                                                    className='form-control'
                                                                                                                    rows='6'
                                                                                                                    value={item.description}
                                                                                                                    onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'description')}
                                                                                                                    placeholder="Description"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {isActive('Pre-Requisites', parentIndex, i, subIndex) && item.prerequisite !== "" && (
                                                                                                            <>
                                                                                                                <h4 className='editor_text'>Prerequisite</h4>
                                                                                                                <textarea
                                                                                                                    className='form-control'
                                                                                                                    rows='6'
                                                                                                                    value={item.prerequisite}
                                                                                                                    onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'prerequisite')}
                                                                                                                    placeholder="Prerequisite"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                        {isActive('Conclusion', parentIndex, i, subIndex) && item.conclusion !== "" && (
                                                                                                            <>
                                                                                                                <h4 className='editor_text'>Conclusion</h4>
                                                                                                                <textarea
                                                                                                                    className='form-control'
                                                                                                                    rows='6'
                                                                                                                    value={item.conclusion}
                                                                                                                    onChange={(e) => handleInputTextBoxNew(e.target.value, parentIndex, i, subIndex, 'conclusion')}
                                                                                                                    placeholder="Conclusion"
                                                                                                                />
                                                                                                            </>
                                                                                                        )}
                                                                                                    </div>
                                                                                                );
                                                                                            }

                                                                                            // If it is a file-based item (file_id exists and file_type is not "text")
                                                                                            if (item.file_id) {
                                                                                                return (
                                                                                                    <CardWithImage
                                                                                                        key={itemIndex}
                                                                                                        title={item.file_name}
                                                                                                        btnType={item.mark_demo}
                                                                                                        data={item}
                                                                                                        type={item.file_type}
                                                                                                        onRemove={() => handleRemoveFile(item.file_id)}
                                                                                                    />
                                                                                                );
                                                                                            }

                                                                                            return null; // Default return if no condition matches
                                                                                        })}
                                                                                    </div>
                                                                                )} */}

                                                                            </div>
                                                                        </React.Fragment>))}
                                                                </React.Fragment>
                                                            ))}
                                                        </div>
                                                    );
                                                }) : null}
                                            </div>
                                        </>)}
                                        <div className='py-3 px-4'>
                                            <ButtonComponent buttonType={'primary'} title="Add New Section" onClick={e => handleAddNewPart()} />
                                        </div>
                                    </div>
                                </>)}
                        </div>
                        <div className='new-curriculam-sidebar-last'>
                            {
                                newCurriculamState === 'basic-details' ?
                                    (<>
                                        <div>
                                            <button style={{ backgroundColor: isActiveNext ? "#0258a1" : "#C4C4C4", border: isActiveNext ? "" : "none" }} onClick={e => handleCurriculamState()} type="button" className={`btn btn-primary w-100 py-2`}>Next {/*<IoMdArrowDropdown size={20} className='float-end' color='white' />*/}</button>
                                        </div>
                                    </>) : (<>
                                        <div className='d-flex justify-content-between align-items-end mb-4 gap'>
                                            <div className='d-flex flex-column' >
                                                <span className='private-public-span'>Private/Public</span>
                                                <span className='setcurriculam-span'>Set the curriculum public  </span>
                                            </div>
                                            <div><>
                                                <div className='toggle-div-style' style={{ fontSize: "12px" }}>
                                                    <Toggle
                                                        defaultChecked={true}
                                                        icons={{
                                                            checked: "Public",
                                                            unchecked: "Private"
                                                        }}
                                                        onChange={() => {
                                                            setContent_status(!content_status)
                                                        }
                                                        }
                                                        // checked={content_status.includes(i)}
                                                        checked={content_status}
                                                    />
                                                </div>
                                            </></div>
                                        </div>
                                        {!editId && <button className='save-as-draft-container p-3' onClick={() => handleSubmit("3")}>
                                            <RxFileText strokeWidth={0.1} size={30} color='#838383' /> <div>Save as Draft</div>
                                        </button>}
                                        <div>
                                            <button type="button" className="btn btn-primary w-100 py-2 text-center submit-btn" onClick={e => setIsModalOpen(true)}>{editId ? "Update and Publish" : "Publish"}{/* <IoMdArrowDropdown size={20} className='float-end' color='white' />*/}</button>
                                        </div>
                                    </>)

                            }
                        </div>
                    </div>


                    {newCurriculamState === 'basic-details' ? <div className='d-flex flex-column justify-content-center align-items-center flex-grow-1 new-curriculam-second-part-section' >
                        <div className='basic_details_img'>
                           <div className='demo-img'>
                           <img src={newcurriculamImage} />
                           <p >Get started by simply clicking the <span>Next</span></p>
                            </div>
                        </div>
                    </div> : load ? (<>
                        <div className='w-100 h-100 flex-center'>
                            <SpinnerComponent />
                        </div>
                    </>) : (<>
                        <div className='overflow-auto w-100 overview-container'>
                            <div className='new-curriculam-second-part-section p-4'>
                                <div className='w-100'>
                                    {newDemoData?.map((subject, subjectIndex) => (
                                        <div key={`subject-preview-${subjectIndex}`} className="subject-preview">
                                            <div
                                                onClick={() => handleSubjectClick(subjectIndex)}
                                                className='indexing mb-2 cursor-pointer d-flex content-list align-items-center px-4 list pt-3 subject_heading_div'
                                            >
                                                <img src={DotsSixVertical} />
                                                <div className='subject_preview_heading'>
                                                    {subjectIndex + 1}.
                                                    <span className='subject_name_preview'>{subject.name}</span>
                                                    <span className='dropdown_arrow'>
                                                        {activeSubjects.includes(subjectIndex) ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                                    </span>
                                                </div> {/* Display Subject Name */}
                                            </div>
                                            {activeSubjects.includes(subjectIndex) && subject.topics?.map((topic, topicIndex) => (
                                                <div key={`topic-preview-${subjectIndex}-${topicIndex}`} className="topic-preview" style={{ marginLeft: "34px" }}>
                                                    <div
                                                        onClick={() => handleTopicClick(subjectIndex, topicIndex)}
                                                        className='cursor-pointer d-flex content-list align-items-center list py-3 subject_heading_div topics_preview_heading flex-grow-1'
                                                    >
                                                    <img src={DotsSixVertical} style={{height:"20px", width:"20px"}} />
                                                        <div style={{ paddingLeft: "10px" }}>
                                                            {subjectIndex + 1}.{topicIndex + 1}
                                                            <span className='subject_name_preview'>{topic.name}</span>
                                                        </div>
                                                        <div className='dropdown_arrow'>
                                                            {activeTopics[subjectIndex]?.includes(topicIndex) ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                                                        </div>
                                                    </div> {/* Display Topic Name */}
                                                    {activeTopics[subjectIndex]?.includes(topicIndex) && topic.sub_topics?.map((subTopic, subTopicIndex) => (
                                                        <div key={`subtopic-preview-${subjectIndex}-${topicIndex}-${subTopicIndex}`} className="subtopic-preview ml-5 d-flex align-items-center" style={{marginLeft:"20px"}}>
                                                              <img src={DotsSixVertical} style={{height:"20px", width:"20px", marginLeft:"10px"}} />
                                                            <div className='d-flex content-list align-items-center list py-3 subject_heading_div subtopic_preview_heading'>
                                                                {subjectIndex + 1}.{topicIndex + 1}.{subTopicIndex + 1}
                                                                <span className='subject_name_preview'>{subTopic.name}</span>
                                                            </div> {/* Display Subtopic Name */}
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    {/* <div className=' d-flex flex-wrap justify-content-between align-items-center mb-4 gap-1' >
                                        <div className='d-flex align-items-center add-curriculam-section-div'>
                                            <div className='d-flex px-2 align-items-center justify-content-center'>
                                                <img src={file} />
                                            </div>
                                            <h2>Add Curriculum </h2>
                                        </div>
                                        
                                        <div className='d-flex gap'>
                                            <img src={leftTiltedArrow} style={{ cursor: 'pointer' }} />
                                            <img src={rightTiltedArrow} style={{ cursor: 'pointer' }} />
                                        </div> 
                                    </div> */}

                                    <div className='curriculum-card mt-4'>
                                        {showTopicImg && showTopicImg?.length === 2 && newDemoData[showTopicImg[0]].topics[showTopicImg[1]].file_data?.map((elm, i) => (
                                            // Only render CardWithImage if file_id is present
                                            elm.file_id ? (
                                                <CardWithImage
                                                    onClickDemo={() => handleDemoContent(i)}
                                                    title={elm.name}
                                                    btnType={elm.mark_demo}
                                                    data={elm}
                                                    type={"pdf"}
                                                    onRemove={() => { editId && elm.id ? handleDeleteFile(elm.id) : removeContentCard(i) }}
                                                />
                                            ) : ""
                                        ))}

                                        {showTopicImg && showTopicImg?.length === 3 && newDemoData[showTopicImg[0]].topics[showTopicImg[1]].sub_topics[showTopicImg[2]].file_data?.map((elm, i) => (
                                            // Only render CardWithImage if file_id is present
                                            elm.file_id ? (
                                                <CardWithImage
                                                    onClickDemo={() => handleDemoContentSubTopic(i)}
                                                    title={elm.name}
                                                    btnType={elm.mark_demo}
                                                    data={elm}
                                                    type={"pdf"}
                                                    onRemove={() => { editId && elm.id ? handleDeleteFile(elm.id) : removeContentCard(i, "sub_topic") }}
                                                />
                                            ) : ""
                                        ))}
                                    </div>
                                    {/* <div className={`pop-over  rounded ${addFiles ? '' : 'hidebox'}`} style={{ boxShadow: "0px 0px 12px 0px #002D7D33", minWidth: '300px', alignSelf: 'flex-end' }}>
                                        <div className='d-flex justify-content-between align-items-center p-2  border-bottom' >
                                            <div className='pop-over-files-details'>2 Files Uploading</div>
                                            <div className='d-flex align-items-center'>
                                                <div><img src={downArrow} /></div>
                                                <div style={{ cursor: 'pointer' }} onClick={e => setAddFiles(!addFiles)}><img src={crossArrow} /></div>
                                            </div>
                                        </div>
                                        <div className={`p-2 d-flex flex-column `} >
                                            {
                                                addFilesArray?.map((element, parentIndex) => {
                                                    return (
                                                        <div className='d-flex justify-content-between align-items-center mb-2'>
                                                            <div className='d-flex align-items-center gap'>
                                                                <div><img src={pdf} /></div>
                                                                <div style={{
                                                                    fontSize: "12px",
                                                                    fontWeight: "500",
                                                                    color: "#838383"

                                                                }} >History.pdf</div>
                                                            </div>
                                                            <div><img src={completed} /></div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </>)}
                </div>

            </div>
            {/* <CardWithImage />

            <AddLinkModal />

            <SchoolCard /> */}

            {isModalOpen && <ModalAssign isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={title} grade={classDropdown.filter(io => io.id === classId)[0].label} category={categoryDropdown && categoryDropdown.filter(io => io.id === selectCate)[0].label} part={newDemoData?.length} contentData={newDemoData} links={0} handleSubmit={handleSubmit} />}
            {isFileModal && <UploadFile editId={editId} handleUpload={handleTopicWiseImage} topicIds={showTopicImg} isOpen={isFileModal} setIsOpen={setIsFileModal} data={newDemoData} />}
            {mediaLinkModalOpen && <UploadMediaLink showTopicImg={showTopicImg} curriculumData={newDemoData} setCurriculumData={setNewDemoData} isOpen={mediaLinkModalOpen} setIsOpen={setMediaLinkModalOpen} />}
        </div >
    )
}

export default NewCurriculam