import React from 'react'
import { Card } from 'react-bootstrap'
import file from "../../../../../images/file.svg"
import './recent.css'
import ButtonComponent from '../../../../../@core/components/button/ButtonComponent'
import { InfinitySpin } from 'react-loader-spinner'
import { useNavigate } from 'react-router-dom'

const SpinnerComponent = () => {
  return (<div className='spinner-component'>
    <InfinitySpin
      visible={true}
      width="200"
      color="#0258a1"
      ariaLabel="infinity-spin-loading"
    />
  </div>)
}

const Recent = ({ data, load }) => {
  const navigate = useNavigate()

  const schoolDetailsSubject = [
    {
      name: "English Compositions",
      time: "4 Min Before"
    },
    {
      name: "Statistics",
      time: "2 Days Before"
    },
    {
      name: "Hindi Poems",
      time: "4 Months Before"
    },
    {
      name: "EVS Mock Papers",
      time: "1 Yr Before"
    },

  ]

  return (
    <Card className='mt-4 p-4'>
      <p>Recent</p>
      {load ? (<div className='d-flex align-items-center justify-content-center'>
        <SpinnerComponent />
      </div>) : <div className='home-recent-div mt-4'>
        {
           data && data.length > 0 && data.map((element, idx) => {
            return (
              <div className='d-flex align-items-center flex-row  align-content-center gap mb-4' >
                <div className='home-recent-div-image' >
                  <img src={file} />
                </div>
                <div className='subject-details-recent-section'>
                  <h2>{element.title ? element.title : ""}</h2>
                  <p>Last Updated: <span>{element.updated_at ? element.updated_at : ""}</span></p>
                </div>
                <div className='d-flex flex-fill justify-content-end'>
                  <ButtonComponent onClick={() => navigate("/modules/curriculum/addcurriculum", { state: { id: element.id, isNotAside: true } })} buttonType={'primary'} title={'Edit'} />
                </div>
              </div>
            )
          })

        }
      </div>}
    </Card>
  )
}

export default Recent