import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import leftArrow from "../../../images/leftArrow.svg"
import ButtonComponent from '../../../@core/components/button/ButtonComponent'
import Datatable from '../../../@core/components/DataTable/DataTable'
import { FaEdit } from "react-icons/fa"
import { RiShakeHandsFill } from "react-icons/ri"
import TableOptions from "../../../@core/components/table-options"
import SwitchTabBtn from "../../../@core/components/SwitchTabBtn";
import loadable from "react-loadable";
import { Archive, ArrowDownCircle, CheckSquare, Eye, Layers, Printer, Trash, Trash2 } from 'react-feather'
import Toggle from 'react-toggle'
import "./curriculam.css"
import { confirmAlert } from 'react-confirm-alert'
import { toast } from "react-toastify"
import axios from 'axios'
import NewCurriculam from './NewCurriculam/NewCurriculam'
import { useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment/moment'
import { InfinitySpin } from 'react-loader-spinner'
import { Tabs, Tab } from 'react-bootstrap';
import CustomInput from '../../../@core/components/CustomInput/CustomInput'
// import RemarksTable from './Evaluation/Remark/Remark'
// import Skill from './Evaluation/Skill/Skill'

const SpinnerComponent = () => {
  return (<div className='spinner-component'>
    <InfinitySpin
      visible={true}
      width="200"
      color="#0258a1"
      ariaLabel="infinity-spin-loading"
    />
  </div>)
}
const RemarksTable = loadable({
  loader: () => import(/* webpackChunkName: 'School' */ "./Evaluation/Remark/Remark"),
  loading: () => <></>,
});

const Skill = loadable({
  loader: () => import(/* webpackChunkName: 'Skill' */ "./Evaluation/Skill/Skill"),
  loading: () => <></>,
});
const Category = loadable({
  loader: () => import(/* webpackChunkName: 'Category' */ "./Category/index"),
  loading: () => <></>,
});
const Curriculam = () => {
  const baseUrl = process.env.REACT_APP_API_URL
  const authentication_key = localStorage.getItem('authentication_key');
  const navigate = useNavigate();
  const [flag, setFlag] = useState(true)
  const [curriculamState, setCurriculamState] = useState('Curriculam')
  const [currentPage, setCurrentPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalPage, setTotalPage] = useState(1)
  const [tableData, setTableData] = useState([])
  const [load, setLoad] = useState(false)
  const [tab, setTab] = useState("curriculums");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const btnNameArray = [
    {
      label: "Curriculums",
      value: "curriculums",
      isActive: true,
    },
    {
      label: "Category",
      value: "category",
      isActive: true,
    },
    {
      label: "Legends",
      value: "skill",
      isActive: true,
    }
  ];

  const switchTabBtnClick = (btnName) => {
    let { button_name, index } = btnName
    setTab(button_name)
    setActiveIndex(index)
    // redirectSwitchTab({ switchTabArray: btnNameArray.filter(io => io.isActive === true), value: btnName.button_name, setActiveIndex, switchTab: setTab })
  };
  const headers = {
    'Authentication_key': authentication_key, // Include any authorization token if needed
    'Authorization': 'Bearer ' + authentication_key,
    // Add other headers as needed
  };
  
  const handleChangeStatus = (id) => {
    axios.postForm(`${baseUrl}/partner/curriculum-change-status`, {
      id,
      auth_bearer_token: authentication_key
    }, {
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
      }
    }).then(res => {
      const data = res.data
      if (data.status === "success") {
        setFlag(!flag)
        toast.success(data.message)

      } else {
        toast.error(data.message)
      }
    }
    ).catch(err => {
      console.log(err)
    })
  }

  const handleGetTableData = () => {
    setLoad(true)
    const payload = {
      page: currentPage,
      perPage: rowsPerPage,
      auth_bearer_token: authentication_key
    }
    axios.get(`${baseUrl}/partner/curriculum-table-data`, {
      headers,
      params: payload
    }).then(res => {
      const response = res.data
      if (response.status === "success") {
        const data = response.data
        setTableData(data)
        setTotalPage(response.total_pages)
        setLoad(false)
      } else {
        console.log(response.message)
        setLoad(false)
      }
    }
    ).catch(err => {
      console.log(err)
      setLoad(false)
    })

  }
  const handleDelete = (id) => {
    axios.postForm(`${baseUrl}/partner/curriculum-destroy`, {
      id,
      auth_bearer_token: authentication_key
    }, {
      headers: {
        'Content-Type': 'application/json', // Set the content type based on your API requirements
        'authentication_key': authentication_key, // Include any authorization token if needed
        'Authorization': 'Bearer ' + authentication_key,
        // Add other headers as needed
      }
    }).then(res => {
      const data = res.data
      if (data.status === "success") {
        setFlag(!flag)
        toast.success(data.message)

      } else {
        toast.error(data.message)
      }
    }
    ).catch(err => {
      console.log(err)
    })
  }
  const handleDeleteData = (row) => {
    confirmAlert({
      title: "Confirm delete",
      message: 'Are you sure to delete?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            handleDelete(row.id)
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Handle the action when 'No' is clicked
            // console.log('You clicked No!');
          },
        },
      ],
      // Custom styles for the confirm pop-up
      overlayClassName: 'custom-overlay-class',
      className: 'custom-modal-class',
    });
  }

  const columns = [
    {
      name: "Curriculum",
      cell: (row) => (<div style={{ cursor: "pointer" }} onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{row.name ? row.name : "-"}</div>)
    },
    {
      name: "No. of curriculums",
      center: true,
      cell: (row) => (
        <div>
          {row.total_curriculum ? row.total_curriculum : "--"}
        </div>
      )
    },
    {
      name: "Created At",
      center: true,
      cell: (row) => (<div onClick={() => navigate("/modules/curriculum/view", { state: { id: row.id, isNotAside: true } })}>{moment(row.created_at * 1000).format("Do MMM, YYYY")}</div>)
    },
    {
      name: "Option",
      center: true,
      cell: row => {

        const tableMenuType = [
          {
            value: "optionDropdown",
            isActive: false
          },
          {
            value: "optionIcon",
            isActive: true
          }
        ]

        const tableOptionArray = [
          {
            name: "View",
            value: "view",
            icon: <Eye
              size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
              className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
            />,
            className: "",
            isActive: true
          }
          // {
          //   name: "Edit",
          //   value: "edit",
          //   icon: <FaEdit
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // },

          // {
          //   name: "Delete",
          //   value: "delete",
          //   icon: <Trash2
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // },
          // {
          //   name: `Mask as ${row.status === "1" ? "Unpublish" : "Publish"}`,
          //   value: "markAs",
          //   icon: <CheckSquare
          //     size={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? 15 : 18}
          //     className={tableMenuType.filter(io => io.isActive === true)[0].value === "optionDropdown" ? "color-white" : "default-color"}
          //   />,
          //   className: "",
          //   isActive: true
          // }
        ]

        const handleTableOption = (e) => {
          if (e.value === "delete") {
            handleDeleteData(row)
          } else if (e.value === "view") {
            navigate("/modules/curriculum/list", { state: { id: row.id, isNotAside: false } })
          } else if (e.value === "edit") {
            navigate("/modules/curriculum/addcurriculum", { state: { id: row.id, isNotAside: true } })
          } else if (e.value === "markAs") {
            handleChangeStatus(row.id)
          }
        }

        return (<>
          {/* <div className="ml-2 view-table-option-tour"> */}
          <TableOptions
            tableOptionArray={tableOptionArray.filter(io => io.isActive === true)}
            handleTableOption={handleTableOption}
            optionType={tableMenuType.filter(io => io.isActive)[0].value}
          />
          {/* </div> */}

        </>)
      }
    }
  ]

  const handlePerPage = (e) => {
    setRowsPerPage(Number(e.target.value))
  }
  const handlePagination = (page) => {
    setCurrentPage(page.selected)
  }
  useEffect(() => {
    handleGetTableData()
  }, [currentPage, rowsPerPage, flag])
  return (<>
    <div className='mb-3'>
      {
        curriculamState === 'Curriculam' ? <Card  >
          <div className='d-flex justify-content-between align-items-center p-3 flex-wrap'>

            <div className='d-flex justify-content-between align-items-center gap' >
              {/* <div className='d-flex justify-content-center align-items-center left-arrow-image'>
                <img src={leftArrow} />
              </div> */}
              <div className='page-heading'>Curriculum</div>
            </div>
            <div className='d-flex justify-content-center align-items-center gap'>
              {/* <ButtonComponent buttonType={'secondary'} title={'Take A Tour'} /> */}
              {tab === "curriculums" && <ButtonComponent buttonType={'primary'} title={'Add New'} onClick={e => navigate('/modules/curriculum/addcurriculum', { state: { isNotAside: true } })} />}
              {tab === "category" ? (
            <ButtonComponent
              buttonType={"primary"}
              title="Add Category"
              onClick={(e) => setModalIsOpen(true)}
            />
          ) : (
            <></>
          )}
            </div>

          </div>
        </Card> :
          <NewCurriculam />
      }
    </div>
    {/* <div className='new_tab'>
      <Tabs
        id="curriculum-skill-tabs"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 gap-3"
      >
        <Tab eventKey="curriculum" title="Curriculum">
          <Card className='overflow-auto curriculum-list'>
            {load ? (<>
              <SpinnerComponent />
            </>) : <Datatable
              columns={columns}
              currentPage={currentPage}
              tableData={tableData}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              isSearchable={false}
              paginate={true}
              handlePerPage={handlePerPage}
              handlePagination={handlePagination}
            />}
          </Card>
        </Tab>
        <Tab eventKey="skill" title="Skill">
          <Tabs
              id="skill_remark_tab"
              activeKey={skillKey}
              onSelect={(k) => setSkillKey(k)}
              className="mb-3 gap-3"
            >
              <Tab eventKey="skills" title="Skills">
                  <Skill/>
              </Tab>
              <Tab eventKey="remarks" title="Remarks">
                  <RemarksTable/>
              </Tab>
            </Tabs>
          </Tab>
      </Tabs>
    </div>   */}
    <SwitchTabBtn
      numberOfButton={btnNameArray.filter((io) => io.isActive === true)}
      switchTabBtnClick={switchTabBtnClick}
      mediaQuery={"415px"}
      isActive={true}
      activeIndex={activeIndex}
    />
    <div className="mt-3">
      {
        tab === "curriculums" ? (<>
          <Card className='overflow-auto curriculum-list p-4'>
            {load ? (<>
              <SpinnerComponent />
            </>) : <Datatable
              columns={columns}
              currentPage={currentPage}
              tableData={tableData}
              totalPage={totalPage}
              rowsPerPage={rowsPerPage}
              isSearchable={false}
              paginate={true}
              handlePerPage={handlePerPage}
              handlePagination={handlePagination}
              noHeader={true}
            />}
          </Card>
        </>) : tab === "skill" ? (<>
          <Skill />
        </>) : tab === "category" && (<>
        <Category isOpen={modalIsOpen} setIsOpen={setModalIsOpen}/>
        </>)
      }
    </div>
  </>)
}

export default Curriculam