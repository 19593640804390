import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle } from 'react-bootstrap'
import search from "../../../../images/search.svg"
import lang from "../../../../images/Lang.svg"
import dashboard from "../../../../images/dashboard.svg"
import ButtonComponent from '../../../../@core/components/button/ButtonComponent'
import SchoolRequest from './SchoolRequests/SchoolRequest'
import Status from './Status/Status'
import TopSchool from './TopSchools/TopSchool'
import Recent from './Recent/Recent'
import axios from 'axios'
import "./home.css"
import DemoRequest from './DemoRequest'

const Home = () => {
    const userName = localStorage.getItem("user_name")
    const baseUrl = process.env.REACT_APP_API_URL
    const authentication_key = localStorage.getItem('authentication_key');
    const headers = {
        'Authentication_key': authentication_key,
        'Authorization': 'Bearer ' + authentication_key
    };
    const [recentCurriculum, setRecentCurriculum] = useState("")
    const [curriculumData, setCurriculumData] = useState("")
    const [load, setLoad] = useState(false)

    const handleGetDashboardData = () => {
        setLoad(true)
        axios.get(`${baseUrl}/partner/curriculum-dashboard`, {
            headers
        }).then(res => {
            const response = res.data
            if (response.status === "success") {
                const data = response.data
                if (data) {
                    setRecentCurriculum(data.recent_curriculum)
                    setCurriculumData(data.curriculum_data)
                }
                setLoad(false)
            } else {
                console.log(response.message)
                setLoad(false)
            }
        }
        ).catch(err => {
            console.log(err)
            setLoad(false)
        })

    }
    useEffect(() => {
        handleGetDashboardData()
    }, [])

    return (
        <div>
            <Card>
                <CardBody className='d-flex justify-content-between flex-wrap p-3'>
                    <div>
                        <div className='page-heading mb-1' style={{
                            color: "#0258a1",
                            fontWeight: "500"
                        }} >Welcome {userName}!</div>
                        {/* <span style={{ fontSize: '16px', color: "rgba(131, 131, 131, 1)" }}>Home</span> */}
                    </div>
                    {/* <div className='d-flex flex-wrap overflow-auto align-items-center gap'>
                        <div className='d-flex align-items-center home-input-section'>
                            < img src={search} />
                            <input type='text' placeholder='Search' />
                        </div>
                        <ButtonComponent buttonType={"secondary"} title={"Take a tour"} />
                        <div >
                            <img src={lang} />
                        </div>
                        <div>
                            <img src={dashboard} />
                        </div>
                    </div> */}
                </CardBody>

            </Card>
            <div className='mt-4 d-flex gap  flex-wrap'>
                <div style={{ flex: 1 }}>
                    <DemoRequest/>
                    <Recent data={recentCurriculum} load={load} />
                    <Status data={curriculumData} load={load} />
                </div>
                <div style={{ flex: 1 }}>
                    {/* <TopSchool /> */}
                    <div className=''>
                    <SchoolRequest />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home