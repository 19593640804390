import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "../../../pages/modules/DashBoard/Home/Home";
import navigation from "../navigation/navigation";
import Curriculam from "../../../pages/modules/Curriculam/Curriculam";
import Schools from "../../../pages/modules/Schools";
import Subscriptions from "../../../pages/modules/Subscriptions/Subscriptions";
import adminNavigation from "../navigation/adminNavigation";
import { Disc, Circle, X } from "react-feather";
import { Card } from "react-bootstrap";
import demoprofileImage from "../../../images/demoprofileimage.svg";
import doubleArrow from "../../../images/doubleArrow.svg";
import "./sidebar.css";
import { useSelector } from "react-redux";

const Sidebar = ({ isAsideOpen, setAsideOpen }) => {
  const location = useLocation()
  const navigate = useNavigate();
  const userName = localStorage.getItem("user_name");
  const [state, setState] = useState("Home");
  const [showIcon, setShowIcon] = useState("7");
  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1200);

  // Function to check if screen is small
  const handleTabClick = () => {
    if (window.innerWidth < 1200) {
      setAsideOpen(true); // Close the sidebar on small screens
    }
  };

  useEffect(() => {
    // Function to update the state based on the screen width
    const updateScreenSize = () => {
      setIsScreenSmall(window.innerWidth < 1024);
      if (window.innerWidth < 1200) {
        setAsideOpen(true);
      } else if (window.innerWidth >= 1200) {
        setAsideOpen(false);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("resize", updateScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, [setAsideOpen]);

  useEffect(() => {
    // Save the sidebar state to localStorage whenever it changes
    localStorage.setItem("isAsideOpen", JSON.stringify(isAsideOpen));
  }, [isAsideOpen]);
  
  function handleFunction(element) {
    setState(element);
  }
  // function handleHoverEffect(){
  //  if(isAsideOpen){
  //   setAsideOpen(!isAsideOpen)
  //  }
  //  else if(!isAsideOpen){
  //   setAsideOpen(!isAsideOpen)
  //  }
  // }

  // const toggleAside = () => {
  //   setAsideOpen(!isAsideOpen);
  // };

  return (
    <div
      className={`aside-bar-container ${
        !isAsideOpen ? "margin-right " : "margin-right-not"
      }`}
      // onMouseEnter={e => handleHoverEffect()} onMouseLeave={e => handleHoverEffect()}
    >
      <div style={{ position: "relative" }}>
        <Card
          className={`aside-bar ${
            isAsideOpen ? "hide" : ""
          } d-flex flex-column`}
          style={{ boxShadow: "0px 4px 18px 0px #0000001A" }}
        >
          <div className="position-relative">
            <div className="px-4 pt-3 float-end">
              {showIcon === "34" || window.innerWidth < 1200 ? (
                <X
                  color="#0258a1"
                  className="float-end"
                  onClick={() => setAsideOpen(!isAsideOpen)}
                />
              ) : isAsideOpen ? (
                <Circle
                  className="float-end"
                  color="#0258a1"
                  onClick={() => setAsideOpen(!isAsideOpen)}
                />
              ) : (
                <Disc
                  className="float-end"
                  color="#0258a1"
                  onClick={() => setAsideOpen(!isAsideOpen)}
                />
              )}
            </div>
            <div className="content">
              <ul style={{ listStyleType: "none", padding: "0 1rem" }}>
                <div className="menu-title mb-3 mt-4">
                  {!isAsideOpen ? "Dashboard" : "..."}
                </div>
                {/*  */}
                {navigation.map((element, ind) => {
                  return (
                    <li
                      className="sideBarList"
                      style={{
                        minWidth: "2.6rem",
                        backgroundColor:
                        location.pathname.includes(element.navLink) ? " #0258a1" : "",
                        borderRadius: "10px",
                        padding: "0.2rem",
                      }}
                      key={ind}
                      onClick={handleTabClick}
                    >
                      <NavLink
                        to={element.navLink}
                        style={{
                          textDecoration: "none",
                          flex: 1,
                          borderRadius: "12px",
                        }}
                        className={"d-flex align-item-center gap p-2"}
                        onClick={(e) => handleFunction(element.title)}
                      >
                        <div style={{ marginRight: "0.5rem" }}>
                          {React.cloneElement(element.icon, {
                            style: {
                              ...element.icon.props.style,
                              fill:
                              location.pathname.includes(element.navLink) ? "#ffffff" : "#838383",
                            },
                          })}
                        </div>
                        <div
                          style={{
                            color:
                            location.pathname.includes(element.navLink)  ? " #ffffff" : "#838383",
                            fontWeight: location.pathname.includes(element.navLink)  ? "800" : "",
                          }}
                        >
                          {!isAsideOpen && element.title}
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
              <ul style={{ listStyleType: "none", padding: "0 1rem", marginTop:"1rem" }}>
                <div className="menu-title mt-5 mb-3">
                  {!isAsideOpen ? "Configuration" : "..."}
                </div>
                {/*  */}
                {adminNavigation.map((element, ind) => {
                  return (
                    <li
                    className="sideBarList"
                      style={{
                        minWidth: "2.6rem",
                        backgroundColor:
                        location.pathname.includes(element.navLink) ? " #0258a1" : "",
                        borderRadius: "10px",
                        padding: "0.2rem",
                      }}
                    >
                      <NavLink
                        to={element.navLink}
                        style={{
                          textDecoration: "none",
                          flex: 1,
                          borderRadius: "12px",
                        }}
                        className={"d-flex align-item-center gap p-2"}
                        onClick={(e) => handleFunction(element.title)}
                      >
                        <div style={{ marginRight: "0.5rem" }}>
                          {React.cloneElement(element.icon, {
                            style: {
                              ...element.icon.props.style,
                              fill:
                              location.pathname.includes(element.navLink) ? "#ffffff" : "#838383",
                            },
                          })}
                        </div>
                        <div
                          style={{
                            color:
                            location.pathname.includes(element.navLink)  ? " #ffffff" : "#838383",
                            fontWeight: location.pathname.includes(element.navLink)  ? "800" : "",
                          }}
                        >
                          {!isAsideOpen && element.title}
                        </div>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className=" d-flex flex-column gap end-profile-section-sidebar">
              <div className="d-flex gap profile-container align-items-center" onClick={() => navigate("/modules/account")}>
                <div className="profile-image-section">
                  <div>
                    {" "}
                    <img
                      src={"https://v2dev.schoolbridge.in/images/user.png"}
                    />
                  </div>
                </div>
                {!isAsideOpen && (
                  <>
                    <div className="d-flex flex-column">
                      <span className="profile-name"> {userName}</span>
                      {/* <span className="profile-short-name"> noiagomez23</span> */}
                    </div>
                  </>
                )}
              </div>
              {/* <div className="">
              <div className=" d-flex justify-content-between align-items-center status-profile p-4 ">
                <div className="d-flex flex-column">
                  <span className="complete-percentage">80% Completed!</span>
                  <span className="complete-profile-setup" > Complete your profile setup</span>
                </div>
                <div>
                  <div>
                    <img src={doubleArrow}  />
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Sidebar;
